import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';

const ImageSlideshow = ({ images, currentIndex, onLoad, onManualNavigate }) => {
    const [showControls, setShowControls] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const theme = useTheme();

    // Handle fade effect
    useEffect(() => {
        setOpacity(0);
        const timer = setTimeout(() => setOpacity(1), 50);
        return () => clearTimeout(timer);
    }, [currentIndex]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              //  backgroundColor: theme.palette.hero.background
            }}
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
        >
            <IconButton
                sx={{
                    position: 'absolute',
                    left: { xs: 2, sm: 8 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    opacity: 0.8,
                    backgroundColor: theme.palette.background.default,
                    '&:hover': {
                        backgroundColor: theme.palette.background.paper,
                        opacity: 1
                    },
                    padding: { xs: '4px', sm: '8px' },
                    '& .MuiSvgIcon-root': {
                        fontSize: { xs: '1.2rem', sm: '1.5rem' }
                    }
                }}
                onClick={() => onManualNavigate('prev')}
            >
                <ChevronLeftIcon />
            </IconButton>

            <IconButton
                sx={{
                    position: 'absolute',
                    right: { xs: 2, sm: 8 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    opacity: 0.8,
                    backgroundColor: theme.palette.background.default,
                    '&:hover': {
                        backgroundColor: theme.palette.background.paper,
                        opacity: 1
                    },
                    padding: { xs: '4px', sm: '8px' },
                    '& .MuiSvgIcon-root': {
                        fontSize: { xs: '1.2rem', sm: '1.5rem' }
                    }
                }}
                onClick={() => onManualNavigate('next')}
            >
                <ChevronRightIcon />
            </IconButton>

            <img
                src={images[currentIndex]}
                alt={`Slideshow image ${currentIndex + 1}`}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    opacity: opacity,
                    transition: 'opacity 0.5s ease-in-out'
                }}
                onLoad={onLoad}
            />
        </Box>
    );
};

export default ImageSlideshow; 
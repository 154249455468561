import React, { useState } from 'react';
import { 
    Box, 
    Typography,
    Button,
    useTheme
} from '@mui/material';
import { AuthDialog } from './AuthDialog';
import CodeManager from './codeowner/CodeManager';

const CodeOwnerConsole = () => {
    const [showAuth, setShowAuth] = useState(false);
    const [authStep, setAuthStep] = useState('initial');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [ownerData, setOwnerData] = useState(null);
    const theme = useTheme();

    const handleAuthSuccess = (userData) => {
        console.log('Auth Success Data:', userData);
        if (!userData.email) {
            console.warn('No email in userData:', userData);
        }
        setIsAuthenticated(true);
        setShowAuth(false);
        setOwnerData({
            ...userData,
            email: userData.email || localStorage.getItem('userEmail')  // Fallback to stored email
        });
    };

    const handleStartAuth = () => {
        setShowAuth(true);
        setAuthStep('email');
    };

    return (
        <Box sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            px: 0
        }}>
            {/* Welcome message - always visible */}
            <Box sx={{ 
                flex: 'none',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}> 
                <Typography 
                    variant="h4"
                    noWrap
                    align="left"
                    sx={{ 
                        color: theme.palette.text.primary,
                        fontSize: { xs: '1.0rem', sm: '2.0rem' },
                        mt: { xs: 2, sm: 3 },
                        mb: { xs: 2, sm: 3 },
                        maxWidth: '600px',
                        width: '100%',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 700
                    }}
                >
                    Create and Manage Your Code!
                </Typography>

                {/* <Typography 
                     variant="body1" 
                     align="center"
                     sx={{
                         maxWidth: '600px',
                         mb: 3,
                         fontSize: { xs: '0.9rem', sm: '1.1rem' },
                         color: 'text.secondary',
                         display: '-webkit-box',
                         WebkitLineClamp: {xs: 3, sm: 2},
                         WebkitBoxOrient: 'vertical',
                         overflow: 'hidden',
                         textOverflow: 'ellipsis'
                     }}
                >
                    Connect customers to your online presence with a simple, memorable code from offline advertising.
                    Increase your site conversion rate.
                </Typography> */}
            </Box>

            {/* Auth Container */}
            {!isAuthenticated ? (
                <Box sx={{
                    width: '100%',
                    maxWidth: '500px',
                    mx: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    {!showAuth ? (
                        <Button 
                            variant="contained"
                            onClick={handleStartAuth}
                            sx={{
                                width: '100%',
                                height: '56px',
                                backgroundColor: theme.palette.button.main,
                                color: theme.palette.button.contrastText,
                                fontSize: {xs: '0.9rem', sm: '1rem'},
                                fontWeight: 'bold',
                                mb: {xs: 10, sm: 0}
                            }}
                        >
                            CONTINUE AS A CODE OWNER
                        </Button>
                    ) : (
                        <AuthDialog 
                            embedded={true}
                            onClose={() => {
                                setShowAuth(false);
                                setAuthStep('initial');
                            }}
                            onSuccess={handleAuthSuccess}
                            currentStep={authStep}
                            onStepChange={setAuthStep}
                        />
                    )}
                </Box>
            ) : (
                ownerData && (
                    <CodeManager 
                        ownerData={ownerData}
                    />
                )
            )}
        </Box>
    );
};

export default CodeOwnerConsole; 
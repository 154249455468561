import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      main: '#60A5FA',    // Main blue color
      dark: '#3B82F6',    // Darker blue for hover
      light: '#93C5FD'    // Lighter blue
    },
    action: {
      disabledBackground: 'rgba(96, 165, 250, 0.12)',
      disabled: mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)'
    },
    background: mode === 'dark' ? {
      paper: '#0B1221',      // Header and hero container background
      default: '#060B14',    // Main background - darkest
      secondary: '#0D1627',  // New - one step lighter than paper
      tertiary: '#0F172A'    // Lightest background
    } : {
      paper: '#FFFFFF',      // Light mode backgrounds
      default: '#FFFFFF',  
      secondary: '#F9FAFB',  // New - slightly off-white
      tertiary: '#F8F9FA'
    },
    ...(mode === 'dark' ? {
      // Dark mode colors with deeper cosmic blue shades
      text: {
        primary: '#FFFFFF',           // Pure white for main headings
        secondary: 'rgba(255, 255, 255, 0.7)'  // 70% white for supporting text
      },
      button: {
        main: '#60A5FA',      // Bright blue
        hover: '#3B82F6',     // Slightly darker blue
        active: '#2563EB',    // Even darker blue for active
        disabled: 'rgba(96, 165, 250, 0.12)',
        contrastText: '#ffffff',
        transparent: {
            color: '#60A5FA',           // Using primary.main
            hoverColor: '#93C5FD',      // Using primary.light
            activeColor: '#3B82F6',     // Using primary.dark
            disabledColor: 'rgba(255, 255, 255, 0.3)'  // Using action.disabled
        }
      },
      shadows: {
        text: '0 2px 4px rgba(0,0,0,0.4)',
        card: '0 1px 3px rgba(6,11,20,0.12)',
      },
      placeholder: {
        text: 'rgba(180, 198, 239, 0.7)', // Blue-tinted placeholder
      },
      scrollbar: {
        track: 'rgba(6,11,20,0.3)',
        thumb: 'rgba(96,165,250,0.2)',
        thumbHover: 'rgba(96,165,250,0.3)',
      },
      input: {
        background: '#0B1221',           // Keep input box color consistent
        activeBackground: '#0F172A',     // Keep active state
      },
      separator: {
        background: 'rgba(255, 255, 255, 0.2)',
        icon: 'rgba(255, 255, 255, 0.3)',
        hover: 'rgba(255, 255, 255, 0.4)'
      },
      header: {
        background: '#0F172A',  // Changed from rgba to solid color
        textColor: '#F3F4F6'
      },
      hero: {
        background: '#0B1221',  // Match paper color for hero section
      },
      footer: {
        background: '#060B14'  // Hardcoded to match background.default value
      },
      table: {
        border: 'rgba(255, 255, 255, 0.2)',
        headerBg: 'rgba(255, 255, 255, 0.05)',
        resizer: {
          default: 'rgba(255, 255, 255, 0.2)',
          hover: '#60A5FA',     // Using primary.main
          active: '#3B82F6'      // Using primary.dark
        }
      }
    } : {
      // Light mode colors
      text: {
        primary: '#1A1A1A',    // Main text
        secondary: '#4A5568',   // Secondary text
        tertiary: '#718096'     // Less important text
      },
      button: {
        main: '#1976d2',
        hover: '#1565c0',
        disabled: 'rgba(0, 0, 0, 0.12)',
        contrastText: '#000',
        transparent: {
            color: '#1976d2',           // Using primary.main
            hoverColor: '#1976d2',      // Same as main
            activeColor: '#1565c0',     // Using hover color
            disabledColor: 'rgba(0, 0, 0, 0.26)'
        }
      },
      input: {
        background: '#FFFFFF',           // Input default state
        activeBackground: '#F8FAFC',     // Input hover/focus state
        border: 'rgba(0, 0, 0, 0.12)',   // Input borders
        activeBorder: 'rgba(0, 0, 0, 0.2)' // Input focused border
      },
      shadows: {
        text: '0 2px 4px rgba(0,0,0,0.1)',
        card: '0 1px 3px rgba(0,0,0,0.12)',
      },
      placeholder: {
        text: 'rgba(0, 0, 0, 0.6)',
      },
      scrollbar: {
        track: 'rgba(0, 0, 0, 0.1)',
        thumb: 'rgba(0, 0, 0, 0.2)',
        thumbHover: 'rgba(0, 0, 0, 0.3)',
      },
      separator: {
        background: 'rgba(0, 0, 0, 0.08)',
        icon: 'rgba(0, 0, 0, 0.3)',
        hover: 'rgba(0, 0, 0, 0.12)'
      },
      header: {
        background: '#FFFFFF',
        textColor: '#1A1A1A'
      },
      hero: {
        background: '#F8F9FA',
      },
      footer: {
        background: '#F8F9FA',
        text: '#4A5568'
      },
      console: {
        background: '#FFFFFF',
        hoverBackground: '#F8F9FA',
        border: 'rgba(0, 0, 0, 0.08)'
      },
      auth: {
        background: '#FFFFFF',
        formBackground: '#F8F9FA',
        inputBackground: '#FFFFFF',
        border: 'rgba(0, 0, 0, 0.08)'
      },
      table: {
        border: 'rgba(224, 224, 224, 0.4)',
        headerBg: 'rgba(0, 0, 0, 0.04)',
        resizer: {
          default: '#e0e0e0',
          hover: '#60A5FA',      // Using primary.main
          active: '#3B82F6'      // Using primary.dark
        }
      }
    }),
  },
});

export const getTheme = (mode) => createTheme(getDesignTokens(mode)); 